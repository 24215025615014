import React from 'react';

export default function ImageModal({ href, src, paragraph }) {
  return (
    <div className="portfolio-modal mfp-hide" id={href}>
      <div className="portfolio-modal-dialog bg-white">
        <a className="close-button d-none d-md-block portfolio-modal-dismiss" href="/#">
          <i className="fa fa-3x fa-times" />
        </a>
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="text-secondary text-uppercase mb-0">Pousada Geriátrica São Félix</h2>
              <hr className="star-dark mb-5" />
              <img className="img-fluid mb-5" src={src} alt="" />
              <p className="mb-5">{paragraph}</p>
              <a className="btn btn-primary btn-lg rounded-pill portfolio-modal-dismiss" href="/#">
                <i className="fa fa-close" />
                Fechar Janela
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
