import React from 'react';

export default function Services() {
  return (
    <section className="bg-primary" id="services">
      <h2 className="text-center text-uppercase text-light mb-0">Serviços</h2>
      <hr className="star-light mb-5" />
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="text-light col-lg-12 ml-auto">
              <span align="justify" className="lead">
                <h3>EQUIPE MULTIDISCIPLINAR</h3>
                <ul>
                  <li>ENFERMAGEM</li>
                  <li>FISIOTERAPIA</li>
                  <li>FONOAUDIÓLOGA</li>
                  <li>MÉDICO</li>
                  <li>NUTRICIONISTA</li>
                  <li>TERAPIA OCUPACIONAL</li>
                </ul>
                Estamos aqui para servir aos nossos idosos, procurando oferecer o melhor de nossos serviços e acomodações, porque acreditamos que podemos fazer a diferença!
                <br />
                <br />
                <h4>Nossas acomodações podem ser: Coletivas ou Individuais</h4>
                <ul>
                  <li>AMBIENTE CALMO</li>
                  <li>AMBIENTE ACOLHEDOR</li>
                  <li>ASSISTÊNCIA 24H POR DIA, 7 DIAS POR SEMANA</li>
                  <li>ASSISTÊNCIA MÉDICA E MEDICAMENTOS</li>
                  <li>APOIO AO IDOSO PERMANENTE</li>
                  <li>ACOMPANHENTO PERSONALIZADO</li>
                  <li>CUIDADOS DE ENFERMAGEM</li>
                  <li>PROFISSIONAIS ESPECIALIZADOS</li>
                </ul>

              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
