import React from 'react';
import ImageModal from './ImageModal';

export default function Image({ href, src }) {
  return (
    <div className="col-md-6 col-lg-4">
      <a className="portfolio-item d-block mx-auto" href={`#${href}`}>
        <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
          <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
            <i className="fa fa-search-plus fa-3x" />
          </div>
        </div>
        <img className="img-fluid" src={src} alt="" />
      </a>
      <ImageModal src={src} href={href} />
    </div>
  );
}
