import React, { useState } from 'react';
import axios from 'axios';

export default function Contact() {
  const initialState = {
    nome: '',
    email: '',
    contato: '',
    mensagem: '',
  };
  const [state, setState] = useState(initialState);

  function handleChange(e) {
    setState({ [e.target.name]: e.target.value });
  }

  async function handleSubmit() {
    if (Object.keys(state).filter((key) => state[key] === '').length) {
      alert('Formulário Incompleto');
      return;
    }

    try {
      await axios.post('https://pousadasaofelix.com/api/faleconosco', { ...state, api: true });
      setState(initialState);
      alert('Mensagem enviada com sucesso, a Pousada Geriátrica São Félix entrará em contato em breve.');
    } catch (err) {
      alert('Não foi possível enviar essa mensagem até a Pousada Geriátrica Sâo Félix, tente novamente mais tarde.');
    }
  }

  return (
    <section id="contact">
      <div className="container">
        <h2 className="text-center text-uppercase text-secondary mb-0">Fale Conosco</h2>
        <hr className="star-dark mb-5" />
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <form onSubmit={handleSubmit} name="sentMessage" id="contactForm" noValidate="novalidate">
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Name</label>
                  <input onChange={handleChange} value={state.nome} className="form-control" name="nome" type="text" placeholder="Nome Completo" required="required" data-validation-required-message="Por Favor, Informe seu nome." />
                  <p className="help-block text-danger" />
                </div>
              </div>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Email Address</label>
                  <input onChange={handleChange} value={state.email} className="form-control" name="email" type="email" placeholder="Email" required="required" data-validation-required-message="Por favor, informe seu email." />
                  <p className="help-block text-danger" />
                </div>
              </div>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Phone Number</label>
                  <input onChange={handleChange} value={state.contato} className="form-control" name="contato" type="tel" placeholder="Telefone" required="required" data-validation-required-message="Por favor informe o número de telefone." />
                  <p className="help-block text-danger" />
                </div>
              </div>
              <div className="control-group">
                <div className="form-group floating-label-form-group controls mb-0 pb-2">
                  <label>Message</label>
                  <textarea onChange={handleChange} value={state.mensagem} className="form-control" name="mensagem" rows="5" placeholder="Mensagem" required="required" data-validation-required-message="Por favor insira uma mensagem.">{state.mensagem}</textarea>
                  <p className="help-block text-danger" />
                </div>
              </div>
              <br />
              <div id="success" />
              <div className="form-group">
                <button type="submit" className="btn btn-primary btn-xl" id="sendMessageButton">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
