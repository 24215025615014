import React from 'react';

import Home from './Sections/Home';
import Galery from './Sections/Galery';
import Contact from './Sections/Contact';
import Services from './Sections/Services';
import Location from './Sections/Location';

export default function Body() {
  return (
    <div className="Body">
      <Home />
      <Services />
      <Location />
      <Galery />
      <Contact />
    </div>
  );
}
