import React from 'react';
import Image from '../Item/Image';

export default function Galery() {
  const imagesJPG = [...new Array(30)];
  return (
    <section className="bg-primary mb-0" id="galery">
      <div className="container">
        <h2 className="text-center text-uppercase text-light mb-0">Galeria</h2>
        <hr className="star-light mb-5" />
        <div className="row">
          { imagesJPG.map((_, index) => (
            <Image
              key={index}
              alt={index}
              href={`modal_${index}`}
              src={`/img/galery/${index + 2}.jpeg`}
            />
          ))}
        </div>
      </div>
    </section>

  );
}
