import React from 'react';

export default function Home() {
  return (
    <section className="portfolio" id="portfolio">
      <h2 className="text-center text-uppercase text-secondary mb-0">Sobre Nós</h2>
      <hr className="star-dark mb-5" />
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ml-auto">
              <p align="justify" className="lead">
                {` Há mais de 21 anos com experiência em cuidar dos idosos, a Pousada Geriátrica São Felix, oferece as melhores condições para uma melhor qualidade de vida na terceira idade, promovendo o bem estar e assegurando os direitos estabelecidos no Estatuto do Idoso.

                Comos serviços e instituições da terceira idade crescendo cada dia mais, procuramos des mitificar a imagem das instituições de longa permanência, com serviços que acrescentem valor e que contribuem para um envelhecimento feliz e tranquilo. Tendo um ambiente calmo e acolhedor que proporciona conforto e harmonia, possuímos um espaço privilegiado com uma ampla área verde e arejada, espaço de lazer e atividades de inclusão social para que nossos hóspede se sintam bem e aconchegados e assim possamos proteger e amparar os idosos promovendo a vida com dignidade e garantindo condições de conforto, segurança e bem estar.`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
