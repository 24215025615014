import React from 'react';

export default function Location() {
  return (
    <section id="location">
      <h2 className="text-center text-uppercase text-secondary mb-0">Localização</h2>
      <hr className="star-dark mb-5" />
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ml-auto">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    title="MapFrame"
                    width="1200"
                    height="400"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Rua%20Alcides%20Mota%20Zilocovick%2C%20840%20-%20Candeias&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />

                </div>
                <style />

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
