import React from 'react';
import Li from './Item/Li';

export default function Topbar() {
  return (
    <div className="Topbar">
      <nav className="navbar navbar-expand-lg bg-primary fixed-top text-uppercase" id="mainNav">
        <div className="container">
          <a className="navbar-brand js-scroll-trigger" href="#home">Pousada São Felix</a>
          <button className="navbar-toggler navbar-toggler-right text-uppercase bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            {' '}
            <i className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <Li href="#portfolio" title="Sobre Nós" />
              <Li href="#services" title="Serviços" />
              <Li href="#location" title="Localização" />
              <Li href="#galery" title="Galeria" />
              <Li href="#contact" title="Contato" />
            </ul>
          </div>
        </div>
      </nav>
      <header id="home" className="masthead bg-primary text-white text-center">
        <div className="container">
          <h1 className="text-uppercase mb-0">Pousada Geriátrica São Félix</h1>
          <hr className="star-light" />
          <h2 className="font-weight-light mb-0">21 anos cuidando de Idosos</h2>
          {' '}
          <br />
          <img className="img-fluid mb-5 d-block mx-auto with-limit" src="/img/galery/1.jpeg" alt="" />
        </div>
      </header>
    </div>
  );
}
