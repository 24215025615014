import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';

import Topbar from './Component/Topbar';
import Body from './Component/Body';
import Footer from './Component/Footer';
import './App.css';

export default function App() {
  useEffect(() => {
    ReactGA.pageview('home');
  }, []);
  return (
    <div className="App">
      <Helmet>
        <title>Pousada Geriátrica São Félix</title>
        <meta
          name="description"
          content="Pousada Geriátrica São Felix R. Alcídes Mota Zilocovick, 840 - Candeias,
          Jaboatão dos Guararapes - PE, 54450-200"
        />
      </Helmet>
      <Topbar />
      <Body />
      <Footer />
    </div>
  );
}
