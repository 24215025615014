import React from 'react';

export default function Footer() {
  return (
    <div className="Footer">
      <footer className="footer text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Localização</h4>
              <p className="lead mb-0">
                R. Alcídes Mota Zilocovick, 840 - Candeias,
                {' '}
                <br />
                {' '}
                Jaboatão dos Guararapes - PE, 54450-200
              </p>
              <p className="lead mb-0">Contatos: 3469-4682 - 81 99922-2179 - 81 98509-3837</p>
            </div>
            <div className="col-md-6 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Redes Sociais</h4>
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-social text-center rounded-circle" href="https://www.facebook.com/pousadageriatricasaofelix?fref=ts">
                    <i className="fa fa-fw fa-facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a target="_blank" rel="noopener noreferrer" className="btn btn-outline-light btn-social text-center rounded-circle" href="https://instagram.com/pousadageriatricasaofelix">
                    <i className="fa fa-fw fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyright py-4 text-center text-white">
        <div className="container">
          <small>
            Pousada Geriátrica São Felix -
            {new Date().getFullYear()}
          </small>
        </div>
      </div>

    </div>
  );
}
